import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client'
import { cache } from '@lib/graphql/cache'
import { SessionExtended } from '@lib/types/nextauth'
import { isDevelopment } from '@lib/utilities/environment'
import { IncomingMessage, ServerResponse } from 'http'
import { useMemo } from 'react'
import { errorLink } from './errorLink'
import { ACCOUNT_FULL_FRAGMENT } from './fragments/Organization'
import {
  cleanTypeNameLink,
  createAuthLink,
  createSplitLink,
  httpLink,
} from './links'

export type ResolverContext = {
  req?: IncomingMessage
  res?: ServerResponse
}

export type ApolloConfig = {
  session?: SessionExtended
}

const isSS = typeof window === 'undefined'
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

function createLink(config: ApolloConfig = {}) {
  const authLink = createAuthLink(config)

  return ApolloLink.from([
    cleanTypeNameLink,
    authLink,
    errorLink,
    isSS ? httpLink : createSplitLink(config.session),
  ])
}

const resolvers = {
  Notification: {
    actor: (notification, _, { cache }) => {
      return cache.readFragment({
        id: 'Account:' + notification?.actor?.id,
        fragment: ACCOUNT_FULL_FRAGMENT,
        fragmentName: 'AccountFullFragment',
      })
    },
  },
}

function createApolloClient(config: ApolloConfig = {}) {
  return new ApolloClient({
    link: createLink(config),
    cache,
    connectToDevTools: isDevelopment(),
    resolvers,
  })
}

export function initializeApollo(
  initialState: any = null,
  config: ApolloConfig = {},
) {
  let _apolloClient

  if (!apolloClient) {
    _apolloClient = createApolloClient(config)
  } else {
    // Recreate links with the latest config
    apolloClient.setLink(createLink(config))

    _apolloClient = apolloClient
  }

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (isSS) return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient
  }

  return _apolloClient
}

export function useApollo(initialState?: any, config: ApolloConfig = {}) {
  const store = useMemo(
    () => initializeApollo(initialState, config),
    [initialState, config],
  )
  return store
}
