import React, { FC } from 'react'

export interface ContainerProps {
  children: React.ReactNode | undefined
}

const Container: FC<ContainerProps> = ({ children }) => {
  return (
    <div>
      <div className="pl-11 mx-auto w-screen h-100">{children}</div>
    </div>
  )
}

export default Container
