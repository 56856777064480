import { path, split, useWith, isNil, map } from 'ramda'
import { BACKEND_DATE_FORMAT } from '@utils/constants'

export const noop = () => {}

// eslint-disable-next-line
export const dotPath = useWith(path, [split('.')])

export const getContrastYIQ = (hexcolor) => {
  hexcolor = hexcolor.replace('#', '')
  var r = parseInt(hexcolor.substr(0, 2), 16)
  var g = parseInt(hexcolor.substr(2, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

export const getRandomHSL = () => {
  const rand = (min, max) => min + Math.random() * (max - min)

  var h = rand(1, 360)
  var s = rand(0, 100)
  var l = rand(30, 80)
  return { h, s, l }
}

export const hslToHex = (h, s, l) => {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = (n) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

export const generateColors = () => {
  return Array.from({
    length: 24,
  })
    .map(() => getRandomHSL())
    .sort((a, b) => {
      return a.l - b.l
    })
    .map(({ h, s, l }) => hslToHex(h, s, l))
}

export const getRandomColor = () => {
  const { h, s, l } = getRandomHSL()

  return hslToHex(h, s, l)
}

export const partitionByIndex = (arr = [], index) => [
  arr.slice(0, index),
  arr.slice(index),
]

export const different = (prev, current) => prev !== current && !isNil(current)

export const ellipsize = (length) => (text) =>
  text.length > length ? `${text.substr(0, length)}...` : text

export const getListIds = map(({ id }) => id)

export const toMap = (list: any[], idProp = 'id') =>
  list.reduce((h, curr) => {
    return {
      ...h,
      [curr[idProp]]: curr,
    }
  }, {})

export const debounce = (timeMs: number, fn: Function) => {
  let _timeout = null

  return (...params: any[]) => {
    if (_timeout) clearTimeout(_timeout)

    return new Promise((resolve) => {
      _timeout = setTimeout(() => resolve(fn(...params)), timeMs)
    })
  }
}
