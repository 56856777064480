import UserDropdown from '@components/Shared/Dropdown/UserDropdown'
import UserContributor from '../AccessComponents/UserContributor'
import { useFormikContext } from 'formik'
import TeamMultiselect from '@components/Shared/Multiselect/TeamMultiselect'
import TeamContributorCard from '../AccessComponents/TeamContributorCard'
import { CONTRIBUTOR_RIGHTS, CONTRIBUTOR_TYPE } from '@utils/constants'
import useTranslations from '@locales/useTranslations'
import { User } from '@lib/types/user'
import useContributorActions from '@lib/hooks/useContributorActions'
import ContributorContainer from '../AccessComponents/ContributorContainer'

export interface ISharedTab {
  isOwnerEditable?: boolean
  resourceOwner?: User
  onOwnerChange: Function
}

const SharedTab = ({
  isOwnerEditable = true,
  resourceOwner,
  onOwnerChange,
}: ISharedTab) => {
  const { values }: any = useFormikContext()

  const { t } = useTranslations()

  const { OWNER } = CONTRIBUTOR_RIGHTS(t)
  const { onContributorAdd: onUserAdd, ...userActions } = useContributorActions(
    CONTRIBUTOR_TYPE.USER,
    resourceOwner,
  )
  const projectResponsible = resourceOwner
    ? values?.userContributors?.find(
        (account) => account.id === resourceOwner?.id,
      )
    : null

  const restUserContributors = resourceOwner
    ? values?.userContributors?.filter(
        (account) => account.id !== resourceOwner?.id,
      )
    : values?.userContributors

  const {
    onContributorAdd: onTeamAdd,
    onTeamDelete,
    ...teamActions
  } = useContributorActions(CONTRIBUTOR_TYPE.TEAM, resourceOwner)

  return (
    <>
      {resourceOwner && (
        <ContributorContainer
          title={t('access.projectOwner')}
          containerClassName="bg-oxford-gray-50"
          contributors={
            <UserContributor
              key={resourceOwner?.id}
              account={resourceOwner}
              access={OWNER.value}
              onOwnerChange={onOwnerChange}
              showBadge={false}
              disabled={!isOwnerEditable}
            />
          }
        />
      )}

      {projectResponsible && (
        <ContributorContainer
          title={t('access.projectOwner')}
          contributors={
            <UserContributor
              key={projectResponsible?.id}
              account={projectResponsible.account}
              disabled
              access={projectResponsible.access}
              isProjectResponsible
            />
          }
        />
      )}

      {!!values?.teamContributors?.length && (
        <ContributorContainer
          title={t('access.teams')}
          contributors={values.teamContributors.map((teamContributor) => (
            <TeamContributorCard
              key={teamContributor.team.id}
              teamContributor={teamContributor}
              onTeamDelete={onTeamDelete}
              projectResponsibleId={projectResponsible?.account?.id}
              resourceOwnerId={resourceOwner?.id}
              {...teamActions}
            />
          ))}
        />
      )}

      <div className="py-3">
        <div className="text-oxford-gray-400 uppercase mb-2 text-xs">
          {t('access.users')}
        </div>
        {!!restUserContributors?.length && (
          <div className="pb-3 flex flex-col gap-3">
            {restUserContributors.map(({ account, access, ...rest }) => (
              <UserContributor
                key={account.id}
                account={account}
                access={access}
                {...rest}
                {...userActions}
              />
            ))}
          </div>
        )}

        <div className="flex justify-between w-100">
          <div className="flex-grow pr-2">
            <TeamMultiselect
              placeholder={`+ ${t('buttons.addTeam')}`}
              onChange={(teams, options) => onTeamAdd(teams, options as any)}
              dataParser={(data) =>
                data.filter(
                  (t) =>
                    !values.teamContributors.find(
                      ({ team }) => team.id === t.id,
                    ),
                )
              }
            />
          </div>

          <div className="flex-grow pl-2">
            <UserDropdown
              placeholder={`+ ${t('buttons.addUser')}`}
              onChange={onUserAdd}
              dataParser={(data) =>
                data.filter(
                  (user) =>
                    user.id !== resourceOwner?.id &&
                    !values.userContributors.find(
                      ({ account }) => user.id === account.id,
                    ),
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SharedTab
